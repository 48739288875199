/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Skeleton, Typography, useTheme } from "@mui/material";
import CapitalizeFirstLetter from "../../../../lib/CapitalizeFirstLetter";

/*
 
& Next, let's style all the components we intend to use on this page.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 2rem",
}));

const SkeletonSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const PropertyListCardStatus = ({
	loading,
	property_status,
	possession_date,
	transaction_type,
	property_type,
}: {
	loading: boolean;
	property_status: Array<string>;
	possession_date: string;
	transaction_type: string;
	property_type: Array<string>;
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<SkeletonSection>
				<Skeleton
					animation="wave"
					variant="text"
					width="30%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</SkeletonSection>
		);
	}

	return (
		<Container>
			<Typography variant="body1">
				{transaction_type === "buy" &&
				(property_type.includes("Land (Residential)") ||
					property_type.includes("Land (Commercial)") ||
					property_type.includes("Land (Agricultural)") ||
					property_type.includes("Land (Industrial)") ||
					property_type.includes("Mining"))
					? "Ready for registration"
					: property_status.includes("Under construction") ||
						  property_status.includes("under_construction") ||
						  property_status.includes("Select date") ||
						  property_status.includes("select_date")
						? new Date(possession_date).toLocaleDateString("en-IN")
						: property_status.map((element: string) =>
								element.length > 20
									? CapitalizeFirstLetter(element.replace(/_/g, " ")).substring(0, 20) + "..."
									: CapitalizeFirstLetter(element.replace(/_/g, " ")),
							)}
			</Typography>
			<Typography
				variant="body2"
				color={theme.palette.text.secondary}
			>
				{property_status.includes("Under construction") ||
				property_status.includes("under_construction") ||
				property_status.includes("Select date") ||
				property_status.includes("select_date")
					? transaction_type === "rent"
						? "Available from"
						: "Possession date"
					: "Status"}
			</Typography>
		</Container>
	);
};

export default PropertyListCardStatus;

/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionRange } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/thunks";

/* redux imports */

/* Stylings */

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0rem",
		fontSize: "0.813rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

const BedroomComponent = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["bedrooms"];
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	// lets create a state to store the selected data
	const minBedrooms: number = search_filter_state
		? (search_filter_state.selected_options[0] as SearchFilterOptionRange)
			? (search_filter_state.selected_options[0] as SearchFilterOptionRange).value
				? (search_filter_state.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (search_filter_state.selected_options[0] as SearchFilterOptionRange).value.min
				: 0
			: 0
		: 0;

	const maxBedrooms: number = search_filter_state
		? (search_filter_state.selected_options[0] as SearchFilterOptionRange)
			? (search_filter_state.selected_options[0] as SearchFilterOptionRange).value
				? (search_filter_state.selected_options[0] as SearchFilterOptionRange).value.unlock_max === true
					? 5
					: (search_filter_state.selected_options[0] as SearchFilterOptionRange).value.max
				: 0
			: 0
		: 0;

	const [state, setState] = React.useState<Array<number>>(
		minBedrooms === 0 && maxBedrooms === 0 ? [] : [minBedrooms, maxBedrooms],
	);

	const bedroom = [
		{
			id: "studio",
			title: "Studio",
			value: 0.5,
		},
		{
			id: "1bhk",
			title: "1 BHK",
			value: 1,
		},
		{
			id: "2bhk",
			title: "2 BHK",
			value: 2,
		},
		{
			id: "3bhk",
			title: "3 BHK",
			value: 3,
		},
		{
			id: "4bhk",
			title: "4 BHK",
			value: 4,
		},
		{
			id: "4+bhk",
			title: "4 + BHK",
			value: 5,
		},
	];

	React.useEffect(() => {
		// if (isDesktop) {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					state.length === 0
						? []
						: [
								{
									id: "bedrooms",
									title: "Bedrooms",
									value: {
										min:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[0] === 5
													? 4
													: state.sort((a, b) => a - b)[0],
										max:
											state.length === 0
												? 0
												: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
													? 4
													: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
										unlock_max: state.includes(5) ? true : false,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "bedrooms",
						title: "Bedrooms",
						value: {
							min: state.length === 0 ? 0 : state.sort((a, b) => a - b)[0] === 5 ? 4 : state.sort((a, b) => a - b)[0],
							max:
								state.length === 0
									? 0
									: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1] === 5
										? 4
										: state.sort((a, b) => a - b)[state.sort((a, b) => a - b).length - 1],
							unlock_max: state.includes(5) ? true : false,
						},
					},
				],
			}),
		);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, dispatch, search_id]);

	React.useEffect(() => {
		// if (isDesktop) {
		const value = search_filter_state.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);

	/* lets create a function to remove the data from the array */

	const handleRemoveData = (value: number) => {
		//lets remove the value from the array

		// lets find the index of the value

		const index = state.indexOf(value);

		// lets remove the value

		if (index > -1) {
			const newState = [...state];
			newState.splice(index, 1);
			setState(newState);
		}
	};

	return (
		<Stack
			direction="row"
			spacing={3}
			// marginBottom={2}
		>
			<FormGroup
				sx={{
					display: "flex",
					flexDirection: "row",
					[theme.breakpoints.down("xsPlus")]: { paddingLeft: "1rem" },
				}}
			>
				{bedroom.map((item) => {
					const inRange = item.value >= minBedrooms && item.value <= maxBedrooms;
					return (
						<FormControlLabel
							key={item.id}
							control={<Checkbox checked={inRange} />}
							label={item.title}
							onChange={() => {
								inRange ? handleRemoveData(item.value) : setState([...state, item.value].sort((a, b) => a - b));
							}}
						/>
					);
				})}
			</FormGroup>
		</Stack>
	);
};
export default BedroomComponent;

import React from "react";
import { styled } from "@mui/system";

import { Skeleton, useTheme } from "@mui/material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "18.25rem",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const AreaStatusContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 0.5rem 0rem",
	gap: "1rem",
}));

const AreaStatusSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const BuilderDetailSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	margin: "0rem 0rem 0.5rem 0rem",
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "60%",
}));

const PropertyListLoadingCard = () => {
	return (
		<React.Fragment>
			<MainContainer>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="23rem"
					height="15.75rem"
					style={{ borderRadius: "16px" }}
				/>
				<Container>
					<Skeleton
						animation="wave"
						variant="text"
						width="30%"
						height="1.5rem"
					/>

					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="1.5rem"
					/>

					<AreaStatusContainer>
						<AreaStatusSkeleton>
							<Skeleton
								animation="wave"
								variant="text"
								width="100%"
								height="auto"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="30%"
								height="auto"
							/>
						</AreaStatusSkeleton>
						<Skeleton
							animation="wave"
							variant="rectangular"
							height="2.5rem"
							width="0.5rem"
							sx={{ borderRadius: "0.25rem" }}
						/>
						<AreaStatusSkeleton>
							<Skeleton
								animation="wave"
								variant="text"
								width="100%"
								height="auto"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="30%"
								height="auto"
							/>
						</AreaStatusSkeleton>
					</AreaStatusContainer>

					<AreaStatusContainer>
						<AreaStatusSkeleton>
							<Skeleton
								animation="wave"
								variant="text"
								width="100%"
								height="auto"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="30%"
								height="auto"
							/>
						</AreaStatusSkeleton>
						<Skeleton
							animation="wave"
							variant="rectangular"
							height="2.5rem"
							width="0.5rem"
							sx={{ borderRadius: "0.25rem" }}
						/>
						<AreaStatusSkeleton>
							<Skeleton
								animation="wave"
								variant="text"
								width="100%"
								height="auto"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="30%"
								height="auto"
							/>
						</AreaStatusSkeleton>
					</AreaStatusContainer>

					<BuilderDetailSkeleton>
						<div style={{ width: "100%" }}>
							<Skeleton
								animation="wave"
								variant="text"
								width="60%"
								height="auto"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="40%"
								height="auto"
							/>
						</div>
						<Skeleton
							animation="wave"
							variant="text"
							width="100%"
							height="3rem"
							style={{
								borderRadius: "12px",
							}}
						/>
					</BuilderDetailSkeleton>
				</Container>
			</MainContainer>
		</React.Fragment>
	);
};

export default PropertyListLoadingCard;

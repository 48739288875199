/* Common imports */
import React from "react";
/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Typography,
	Slide,
	TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* library imports */

import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";
import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";

/* reedux imports */
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomZoomOutMapOutlinedIcon = styled(ZoomOutMapOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "0.5rem",
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "1rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode == "light" ? "#666666" : "",
}));

const CustomSpan = styled("span")(({ theme }) => ({
	color: theme.palette.primary.main,
}));

export const _i_area = ({
	search_id,
	dispatch,
	area,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	area: StateInterface["search_filters_state"]["area"];
}) => {
	const theme = useTheme();

	const [minArea, setMinArea] = React.useState<number>(0);
	const [maxArea, setMaxArea] = React.useState<number>(0);
	const [slide, setSlide] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string>("");
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const handleUpdateMinArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMinArea(CurrencyToNumberPipe(e.target.value));
		if (maxArea !== 0 && CurrencyToNumberPipe(e.target.value) > maxArea) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: 0,
										max: maxArea,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxArea,
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: 0,
										max: maxArea,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxArea,
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	const handleUpdateMaxArea = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxArea(CurrencyToNumberPipe(e.target.value));
		if (
			(minArea !== 0 && CurrencyToNumberPipe(e.target.value) < minArea) ||
			isNaN(CurrencyToNumberPipe(e.target.value))
		) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "area",
									title: "Area",
									value: {
										min: minArea,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	React.useEffect(() => {
		const value = area.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [area, search_id, dispatch]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomZoomOutMapOutlinedIcon />
				<CustomTypography>
					Area <CustomSpan>{"(sqft)"}</CustomSpan> {(minArea || maxArea) > 0 ? <CustomSelectedIcon /> : null}
				</CustomTypography>
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<Text
						value={minArea === 0 ? "" : NumberToCurrencyPipe(minArea)}
						id="outlined-basic"
						placeholder="Min Area"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinArea(e);
						}}
						error={errorMinPrice}
						// helperText={errorMinPrice ? "Value is more than max area" : NumberInWords(minArea)}
						autoComplete="off"
					/>
					<SubText>to</SubText>
					<Text
						value={maxArea === 0 ? "" : NumberToCurrencyPipe(maxArea)}
						id="outlined-basic"
						placeholder="Max Area"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxArea(e);
						}}
						error={errorMaxPrice}
						// helperText={errorMinPrice ? "Value is less than min area" : NumberInWords(maxArea)}
						autoComplete="off"
					/>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};

/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { styled } from "@mui/system";

/*

& Next, let's style all the components we intend to use on this page.

*/

const Image = styled("img")(({ theme }) => ({
	minWidth: "45%!important",
	maxWidth: "45%!important",
	height: "100%",
	objectFit: "cover",
	borderRadius: "16px",
}));

const PropertyListCardImage = ({
	loading,
	imagesrc,
	alt,
	boosting,
}: {
	loading: boolean;
	imagesrc: string;
	alt: string;
	boosting?: any;
	transaction_type: string;
}) => {
	return (
		<Image
			src={imagesrc}
			alt={alt}
			referrerPolicy="no-referrer"
		/>
	);
};

export default PropertyListCardImage;

/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Skeleton, Typography, useTheme } from "@mui/material";

/*

& Next, let's style all the components we intend to use on this page.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0.2rem 0rem 0rem",
}));

const PropertyListCardType = ({ loading, propertyType }: { loading: boolean; propertyType: Array<string> }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Container>
				<Typography variant="body1">
					{propertyType.length > 0
						? propertyType[0].length > 20
							? propertyType[0].substring(0, 20) + "..."
							: propertyType[0]
						: ""}
				</Typography>
				<Typography
					variant="body2"
					color={theme.palette.text.secondary}
				>
					Type
				</Typography>
			</Container>
		</React.Fragment>
	);
};

export default PropertyListCardType;

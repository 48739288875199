/* Common imports */
import React from "react";
/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Slide,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

/* library impoerts */
import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { SearchFilterId } from "../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomCategoryIcon = styled(PaymentsOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "0.5rem",
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "1rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
	},
}));

const HelperText = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	maxWidth: "3.813rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

export const _g_security_deposit = ({
	search_id,
	dispatch,
	security_deposit,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	security_deposit: StateInterface["search_filters_state"]["security_deposit"];
}) => {
	const theme = useTheme();

	const [minSecurityDeposit, setMinSecurityDeposit] = React.useState<number>(0);
	const [maxSecurityDeposit, setMaxSecurityDeposit] = React.useState<number>(0);
	const [slide, setSlide] = React.useState<boolean>(false);
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const handleUpdateMinSecurityDeposit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMinSecurityDeposit(CurrencyToNumberPipe(e.target.value));
		if (maxSecurityDeposit !== 0 && CurrencyToNumberPipe(e.target.value) > maxSecurityDeposit) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: 0,
										max: maxSecurityDeposit,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxSecurityDeposit,
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: 0,
										max: maxSecurityDeposit,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxSecurityDeposit,
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	const handleUpdateMaxSecurityDeposit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxSecurityDeposit(CurrencyToNumberPipe(e.target.value));
		if (
			(minSecurityDeposit !== 0 && CurrencyToNumberPipe(e.target.value) < minSecurityDeposit) ||
			isNaN(CurrencyToNumberPipe(e.target.value))
		) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: minSecurityDeposit,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: minSecurityDeposit,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: minSecurityDeposit,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "security_deposit",
									title: "Security Deposit",
									value: {
										min: minSecurityDeposit,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	React.useEffect(() => {
		const value = security_deposit.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [security_deposit, search_id, dispatch]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomCategoryIcon /> Security Deposit{" "}
				{(minSecurityDeposit || maxSecurityDeposit) > 0 ? <CustomSelectedIcon /> : null}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<Text
						value={minSecurityDeposit === 0 ? "" : NumberToCurrencyPipe(minSecurityDeposit)}
						id="outlined-basic"
						placeholder="Min SecurityDeposit"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinSecurityDeposit(e);
						}}
						error={errorMinPrice}
						// helperText={
						// 	errorMinPrice ? (
						// 		<HelperText>Value is more than max security deposit</HelperText>
						// 	) : (
						// 		<HelperText>{NumberInWords(minSecurityDeposit)}</HelperText>
						// 	)
						// }
						autoComplete="off"
					/>
					<SubText>to</SubText>
					<Text
						value={maxSecurityDeposit === 0 ? "" : NumberToCurrencyPipe(maxSecurityDeposit)}
						id="outlined-basic"
						placeholder="Max SecurityDeposit"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxSecurityDeposit(e);
						}}
						error={errorMaxPrice}
						// helperText={errorMinPrice ? "Value is less than min security deposit" : NumberInWords(maxSecurityDeposit)}
						autoComplete="off"
					/>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};

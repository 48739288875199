/* Common imports */

import React from "react";

/* mui imports */

import {
	useTheme,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	FormControlLabel,
	Typography,
	Slide,
	Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";

/* redux imports */
import { SearchFilterId, SearchFilterOption } from "../../redux-magic/sub-interfaces/search-types";
import StateInterface from "../../redux-magic/state-interface";
import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/store";
import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomStairsOutlinedIcon = styled(StairsOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	// boxShadow: "none",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

interface FloorsProps {
	id: string;
	title: string;
	value: {
		min: number;
		max: number;
		unlock_max: boolean;
	};
}

export const _s_floors = ({
	search_id,
	floors,
	dispatch,
}: {
	search_id: SearchFilterId;
	floors: StateInterface["search_filters_state"]["floors"];
	dispatch: Function;
}) => {
	const theme = useTheme();

	const [state, setState] = React.useState<FloorsProps | null>();

	const [slide, setSlide] = React.useState<boolean>(false);

	const category = [
		{
			id: "ground",
			title: "Ground",
			value: {
				min: 0,
				max: 0,
				unlock_max: false,
			},
		},
		{
			id: "1-10",
			title: "1-10",
			value: {
				min: 1,
				max: 10,
				unlock_max: false,
			},
		},
		{
			id: "10-20",
			title: "10-20",
			value: {
				min: 10,
				max: 20,
				unlock_max: false,
			},
		},
		{
			id: "20-30",
			title: "20-30",
			value: {
				min: 20,
				max: 30,
				unlock_max: false,
			},
		},
		{
			id: "30-40",
			title: "30-40",
			value: {
				min: 30,
				max: 40,
				unlock_max: false,
			},
		},
		{
			id: "40-50",
			title: "40-50",
			value: {
				min: 40,
				max: 50,
				unlock_max: false,
			},
		},
		{
			id: "50+",
			title: "50+",
			value: {
				min: 0,
				max: 50,
				unlock_max: true,
			},
		},
	];

	const handleUpdateFloors = (floor: any) => {
		let newOptionValue: SearchFilterOption = {
			id: "floors",
			title: "Floors",
			value: floor,
		} as SearchFilterOption;
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "floors",
						title: "Floors",
						value: floor,
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "floors",
						title: "Floors",
						value: floor,
					},
				],
			}),
		);
	};

	const handleRemoveFloors = () => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "floors",
						title: "Floors",
						value: {
							min: 0,
							max: 0,
							unlock_max: true,
						},
					},
				],
			}),
		);
	};

	React.useEffect(() => {
		// lets check if floors are selected or not
		let isFloorsSelected = floors ? (floors.selected_options.length != 0 ? true : false) : false;

		// lets update the search filter applied state
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isFloorsSelected }));
	}, [floors, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomStairsOutlinedIcon /> Floor {floors.selected_options.length != 0 && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{category.map((item: FloorsProps) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								if (state?.id === item.id) {
									handleRemoveFloors();
									setState(null);
								} else {
									handleUpdateFloors(item.value);
									setState(item);
								}
							}}
							sx={{
								background: state?.id === item.id ? "#FC801933" : "",
								border:
									state?.id === item.id
										? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
										: "1px solid #C0C0C0",
							}}
						>
							{state?.id === item.id ? <DoneIcon /> : <AddIcon />}
							<Text variant="body1">{item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};

import React from "react";
import { styled } from "@mui/system";

import { Paper, Skeleton, useTheme } from "@mui/material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "8px",
	objectFit: "contain",
	width: "100%",
	padding: "1rem",
	gap: "1rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "60%",
	overflow: "hidden",
	gap: "0.7rem",
}));

const PriceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BookmarkDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
}));

const ButtonDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "1rem 0rem 0rem 0rem",
}));

const NameDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ServiceListLoadingCard = () => {
	const theme = useTheme();
	return (
		<React.Fragment>
			<MainContainer>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="23rem"
					height="15.75rem"
					style={{ borderRadius: "8px" }}
				/>
				<Container>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="30%"
							height="1.5rem"
						/>
						<BookmarkDiv>
							<Skeleton
								animation="wave"
								variant="circular"
								width="8%"
								height="2rem"
							/>
						</BookmarkDiv>
					</PriceDiv>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="1.5rem"
					/>
					<Skeleton
						animation="wave"
						variant="text"
						width="50%"
						height="1.5rem"
					/>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
					</PriceDiv>
					<PriceDiv>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="20%"
							height="1.5rem"
						/>
					</PriceDiv>
					<ButtonDiv>
						<NameDiv>
							<Skeleton
								animation="wave"
								variant="text"
								width="40%"
								height="1.5rem"
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="20%"
								height="1.5rem"
							/>
						</NameDiv>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="40%"
							height="2rem"
							style={{ borderRadius: "8px" }}
						/>
					</ButtonDiv>
				</Container>
			</MainContainer>
		</React.Fragment>
	);
};

export default ServiceListLoadingCard;

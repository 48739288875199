/* Common imports */

import React from "react";

/* mui imports */

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Slide,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../redux-magic/sub-interfaces/search-types";

import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomCategoryIcon = styled(CategoryOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "var(--Spacing-spacing-md, 16px)",
}));

const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "0.5rem",
	padding: "var(--Spacing-spacing-xs, 4px)",
	gap: "var(--Spacing-spacing-xs, 4px)",
	cursor: "pointer",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
	textTransform: "capitalize",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
}));

const DoneIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
	color: "#623816",
	height: "1rem",
	width: "1rem",
}));

const AddIcon = styled(AddOutlinedIcon)(({ theme }) => ({
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	height: "1rem",
	width: "1rem",
}));

export const _z_business_profile_type = ({
	search_id,
	business_profile_type,
	dispatch,
}: {
	search_id: SearchFilterId;
	business_profile_type: StateInterface["search_filters_state"]["business_profile_type"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const [slide, setSlide] = React.useState<boolean>(business_profile_type.is_accordion_open);

	const handleUpdateBusinessProfileType = (value: Array<SearchFilterOptionTextActive>) => {
		// console.log(value, "value");
		if (value.length > 0) {
			let selected_options: Array<SearchFilterOption> = value.map((option) => {
				let { is_selected, ...rest } = option;
				return rest;
			});

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: selected_options,
				}),
			);

			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: search_id,
					newOptions: business_profile_type.active_options.map((item) => ({
						...item,
						is_selected: item.id === value[0]?.id ? true : false,
					})),
				}),
			);
		}
	};

	React.useEffect(() => {
		/*

		 * lets check if the business profile type option is selected

		*/

		let isBusinessProfileTypeCategorySelected = business_profile_type
			? business_profile_type.selected_options.length !== 0
				? true
				: false
			: false;

		/*

		* lets set the business profile type is applied to true if the business profile type is selected

		 */
		dispatch(
			updateSearchFilterAppliedThunk({ searchFilterId: search_id, isApplied: isBusinessProfileTypeCategorySelected }),
		);
	}, [business_profile_type, search_id, dispatch]);

	return (
		<CustomAccordion
			disableGutters={true}
			square={true}
			defaultExpanded={business_profile_type.is_accordion_open as boolean}
			onChange={() => setSlide(!slide)}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Person2OutlinedIcon
					sx={{
						color: "#FC8019",
						marginRight: "1rem",
					}}
				/>{" "}
				Business Profile Type {business_profile_type.is_applied && <CustomSelectedIcon />}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					{business_profile_type.active_options.map((item: SearchFilterOptionTextActive) => (
						<CheckBoxDiv
							key={item.id}
							onClick={() => {
								const isSelected = item.id === business_profile_type?.selected_options[0]?.id;
								isSelected
									? handleUpdateBusinessProfileType([] as Array<SearchFilterOptionTextActive>)
									: handleUpdateBusinessProfileType([item] as Array<SearchFilterOptionTextActive>);
							}}
							sx={{
								background: item.is_selected ? "#FC801933" : "",
								border: item.is_selected
									? "1px solid var(--Other-New-Outline-Border, rgba(255, 255, 255, 0.23))"
									: "1px solid #C0C0C0",
							}}
						>
							{item.is_selected ? (
								<DoneIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							) : (
								<AddIcon sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#623816" }} />
							)}
							<Text variant="body1">{item.title === "Landowner" ? "Owner" : item.title}</Text>
						</CheckBoxDiv>
					))}
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};

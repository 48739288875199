/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import { Skeleton, Typography, useTheme } from "@mui/material";

/*
 
& Next, let's style all the components we intend to use on this page.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	margin: "0rem 0rem 0rem 2rem",
}));

const OFBListCardLocation = ({ location, loading }: { location: string; loading: boolean }) => {
	const theme = useTheme();

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Container>
				<Typography variant="body1">
					{location ? (location.length < 20 ? location : location?.substring(0, 20) + "...") : ""}
				</Typography>
				<Typography
					variant="body2"
					color={theme.palette.text.secondary}
				>
					Location
				</Typography>
			</Container>
		</React.Fragment>
	);
};

export default OFBListCardLocation;

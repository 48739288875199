/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/* Redux Importss */

import { ObjectId } from "mongodb";
import { useRouter } from "next/router";
/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import Link from "next/link";
import { Chip, IconButton, useTheme } from "@mui/material";
import PropertyListCardImage from "./sub-component/PropertyListCardImage";
import PropertyListCardConfigAndContact from "./sub-component/PropertyListCardConfigAndContact";
import PropertyListCardLocation from "./sub-component/PropertyListCardLocation";
import PropertyListCardTitle from "./sub-component/PropertyListCardTitle";
import PropertyListCardPrice from "./sub-component/PropertyListCardPrice";
import PropertyListLoadingCard from "../skeletonLoadingCard/PropertyListLoadingCard";
import { BookmarkBorder, BookmarkOutlined } from "@mui/icons-material";
import PropertyListCardType from "./sub-component/PropertyListCardType";
import PropertyListCardArea from "./sub-component/PropertyListCardArea";
import PropertyListCardStatus from "./sub-component/PropertyListCardStatus";
import PrepareConfigString from "../../../lib/PrepareConfigString";
import prepareConfigTitle from "../../../lib/PrepareConfigTitle";
import { addSaveEntityBusinessProfileThunk } from "../../../redux-magic/store";

/*

& Next, let's style all the components we intend to use on this page.

*/

const PropertyLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	height: "auto",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "18.25rem",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "60%",
	overflow: "hidden",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const IconsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	padding: "0rem 0.5rem",
	borderRadius: "8px",
	backgroundColor:
		transaction_type === "buy"
			? "#b7efc5"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#caf0f8"
					: "#b7efc5",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	padding: "0.25rem",
	width: "2rem",
	height: "2rem",
	borderRadius: "12px",
	background: theme.palette.mode === "dark" ? "#FC801933" : "#FC801929",
	"&:hover": {
		background: "#E6DACF",
	},
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const PropertyCardListView = ({
	PropertyId,
	dispatch,
	session,
	profile_context,
}: {
	PropertyId: ObjectId | string;
	dispatch: Function;
	session: any;
	profile_context: any;
}) => {
	const theme = useTheme();

	const [propertyData, setPropertyData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (PropertyId !== undefined) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
				});
		}
	}, [PropertyId]);

	const [bookmark, setBookmark] = React.useState(
		propertyData.saved_content?.find((item: any) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id ===
						(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
				: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
		}) !== undefined,
	);

	React.useEffect(() => {
		setBookmark(
			propertyData.saved_content?.find((item: any) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id ===
							(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
					: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
			}) !== undefined,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile_context, bookmark]);

	const router = useRouter();

	const pathname = React.useRef(router).current;

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: propertyData._id,
					entity_type: "property",
					add_remove: !bookmark ? "add" : "remove",
					url: pathname.asPath ? pathname.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	if (loading) {
		return <PropertyListLoadingCard />;
	}

	let BoostingCheck = propertyData?.boost_information?.active ? true : false;

	return (
		<PropertyLink
			href={`/properties/${propertyData.slug}`}
			rel="noopener"
			target="_blank"
			referrerPolicy="no-referrer"
			title={propertyData.title || "Unknown Title"}
		>
			<MainContainer
				sx={{
					border: BoostingCheck ? "3px solid #fdb375" : "none",
					background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
				}}
			>
				{/* Image */}

				<PropertyListCardImage
					loading={propertyData.loading}
					imagesrc={propertyData.images}
					alt="Property"
					transaction_type={propertyData.transaction_type}
				/>

				<PropertyCardInfoContainer>
					<PriceAndTransactionContainer>
						{/* Price */}

						<PropertyListCardPrice
							price={propertyData.price}
							loading={propertyData.loading}
						/>

						<IconsContainer onClick={(e) => e.preventDefault()}>
							{/* Transaction Type Chip */}

							<CustomChip
								label={
									propertyData.transaction_type && propertyData.transaction_type === "buy"
										? "Sale"
										: propertyData.transaction_type.charAt(0).toUpperCase() + propertyData.transaction_type.slice(1)
								}
								transaction_type={propertyData.transaction_type}
							/>

							{/* Bookmark Icon */}

							<BookmarkIconButton
								aria-label="bookmark"
								onClick={(e) => {
									submit();
								}}
							>
								{!bookmark ? (
									<BookmarkBorder
										fontSize="small"
										style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#623816" }}
									/>
								) : (
									<BookmarkOutlined
										fontSize="small"
										style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#623816" }}
									/>
								)}
							</BookmarkIconButton>
						</IconsContainer>
					</PriceAndTransactionContainer>

					{/* Title */}

					<PropertyListCardTitle
						loading={loading}
						title={propertyData.title !== "" ? propertyData.title : "Unknown Title"}
					/>

					<CardRow>
						{/* Property Type */}

						<PropertyListCardType
							loading={propertyData.loading}
							propertyType={propertyData.property_type ? propertyData.property_type : []}
						/>

						<VerticalLine />

						{/* Location */}

						<PropertyListCardLocation
							loading={propertyData.loading}
							location={propertyData.location.title !== "" ? propertyData.location.title : "Unknown Location"}
						/>
					</CardRow>

					<CardRow>
						{/* Area */}

						<PropertyListCardArea
							loading={propertyData.loading}
							area={propertyData.area}
							areaType={
								propertyData.property_type
									? propertyData.property_type.includes("Land (Residential)") ||
										propertyData.property_type.includes("Land (Commercial)") ||
										propertyData.property_type.includes("Land (Agricultural)") ||
										propertyData.property_type.includes("Land (Industrial)")
										? "land_area"
										: "super_built_up_area"
									: "super_built_up_area"
							}
						/>

						<VerticalLine />

						{/* Property Status */}

						<PropertyListCardStatus
							loading={loading}
							property_status={propertyData.availability}
							possession_date={propertyData.possession_date}
							transaction_type={propertyData.transaction_type}
							property_type={propertyData.property_type}
						/>
					</CardRow>

					{/* Config and CTA */}

					<PropertyListCardConfigAndContact
						loading={propertyData.loading}
						config={PrepareConfigString(
							propertyData.transaction_type,
							propertyData.property_type,
							propertyData.bhk,
							propertyData.price_per_unit,
							propertyData.floors,
							propertyData.no_of_seats,
						)}
						card_title={prepareConfigTitle(propertyData.transaction_type, propertyData.property_type)}
						dispatch={dispatch}
						session={session}
						slug={propertyData.slug}
						title={propertyData.title}
						created_by={propertyData.created_by}
						show_contact_details={propertyData.show_contact_details}
					/>
				</PropertyCardInfoContainer>
			</MainContainer>
		</PropertyLink>
	);
};

export default PropertyCardListView;

/*

? First, let's import the required components.

*/

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";

import {
	Chip,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Slide,
	Stack,
	SwipeableDrawer,
	Tab,
	Tabs,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
// import { useRouter } from "next/router";
import React from "react";
import {
	ofb_property_category_options_active,
	property_category_options_active,
} from "../../../../lib/data/search-filters/_d_property_category";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import FilterMobileComponent from "../../../search/FilterMobileComponent";
import BedroomComponent from "../SearchComponent/BedroomComponent";
import BudgetComponent from "../SearchComponent/BudgetComponent";
import FacingDirectionComponent from "../SearchComponent/FacingDirectionComponent";
import ListedByComponent from "../SearchComponent/ListedByComponent";
import PropertyStatusComponent from "../SearchComponent/PropertyStatusComponent";
import ServiceCategoryMobile from "../SearchMobileComponent/ServiceCategoryMobile";
import ServiceSubTypeMobile from "../SearchMobileComponent/ServiceSubTypeMobile";
import LocationAutoCompleteMobile from "./LocationAutoCompleteMobile";
import PropertyCategoryMobile from "./PropertyCategoryMobile";
import PropertyTypeMobile from "./PropertyTypeMobile";
const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	padding: "0.5rem 0.5rem 0.5rem 1rem",
	backgroundColor: theme.palette.background.paper,
	borderRadius: "16px",
	color: "#666",
	width: "100%",
	zIndex: 0,
	// boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.12)",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const IconContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	backgroundColor: theme.palette.background.paper,
	borderRadius: "0.5rem",
	color: "#666",
	// boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.12)",
	zIndex: 0,
	[theme.breakpoints.down("md1220")]: { padding: "1rem" },
	[theme.breakpoints.down("sm")]: { padding: "0.8rem" },
	[theme.breakpoints.up("lg")]: {
		display: "none",
	},
}));

const DrawerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	width: "100%",
	gap: "0.5rem",
	borderRadius: "16px",
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "space-between",
		borderRadius: "16px 16px 0px 0px",
		width: "100%",
		height: "100%",
		background: theme.palette.background.default,
	},
}));

const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const LocationChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const StyledTabs = styled(Tabs)(({ theme }) => ({
	width: "fit-content",
	alignSelf: "start",
	borderRadius: "8px",
	backgroundColor: "transparent",
	"&.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-indicator": {
		display: "none",
	},
	"& .MuiButtonBase-root.MuiTabScrollButton-root": {
		display: "none",
	},
})) as typeof Tabs;

const StyledTab = styled(Tab)(({ theme, label }) => ({
	textTransform: "none",
	fontSize: "0.875rem",
	fontWeight: 500,
	gap: "0.5rem",
	letterSpacing: "0.025rem",
	background: "transparent",
	color: theme.palette.mode === "dark" ? "#ffffff" : "#212121",
	"&.Mui-selected": {
		background: theme.palette.mode === "dark" ? "#FF8A29" : "#FC8019",
		color: "#ffffff",
		borderRadius: "12px",
		// border: theme.palette.mode === "dark" ? "1px solid #7fffcf" : "1px solid #1BA672",
	},
})) as typeof Tab;

const CommonContainerSubCategory = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem 0rem 0.5rem 0rem",
}));

const TypographyDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.5rem",
	// marginLeft: "0.25rem",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
}));

const ButtonCompoent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "space-between",
	paddingLeft: "1rem",
	paddingRight: "1rem",
	zIndex: 3,
}));

const TypographyHeading = styled(Typography)(({ theme }) => ({
	display: "flex",
}));

const SearchIconContainer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "2.6rem",
	height: "2.25rem",
	background: "#FC8019",
	borderRadius: "12px",
}));

const Search = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
	historyState,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	historyState: any;
}) => {
	// const router = useRouter();
	const theme = useTheme();

	const BusinessUsers = ["Agents", "Developers", "Landowners", "Professionals"];

	const [trigger, setTrigger] = React.useState(false);
	const DrawerOpen = () => {
		setTrigger(true);
	};
	const DrawerClose = () => {
		setTrigger(false);
	};
	/*

		* Buy, rent ofb ,sservices and business users

	*/
	const [value, setValue] = React.useState(
		search_filter_state.search_type.selected_options[0].id === "properties"
			? search_filter_state.transaction_type.selected_options[0].id === "buy"
				? "Buy"
				: search_filter_state.transaction_type.selected_options[0].id === "rent"
					? "Rent"
					: "Open For Business"
			: search_filter_state.search_type.selected_options[0].id === "services"
				? "Services"
				: "Business Users",
	);

	/*

	* Function to handle the business users agent, developers, landowners, professionals, users

	*/

	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);

		const business_profile_type = search_filter_state.business_profile_type.active_options.map((item) => ({
			...item,
			is_selected: item.title === value ? true : false,
		}));

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: business_profile_type,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: "business_profile_type",
				isAccordionOpen: true,
			}),
		);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		if (value !== newValue) {
			let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
				return (
					search_filter_state[key as SearchFilterId].is_applied === true &&
					key !== "transaction_type" &&
					key !== "search_type" &&
					key !== "location"
				);
			});
			filtersapplied.map((filter) => {
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: filter as SearchFilterId,
						newOptions: [],
					}),
				);
			});
		}

		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/
		if (newValue === "Buy" || newValue === "Open for business" || newValue === "Rent") {
			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: "property_category",
					newOptions: property_category_options_active,
				}),
			);
			/*

			* updating service is applied and is visible

			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

			* updating properties is applied and is visible

			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "business_profile_type",
					newOptions: [],
				}),
			);

			/*

			 * need to update transaction type as well as search type same with isApplied true.

				! default value is done in index.tsx page for transaction_type

			*/

			if (newValue === "Rent") {
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "rent",
								title: "Rent",
								value: "rent",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
			if (newValue === "Open for business") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "ofb",
								title: "Open for business",
								value: "ofb",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: ofb_property_category_options_active,
					}),
				);
			}
		} else if (newValue === "Services") {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
		} else if (newValue === "Business Users") {
			const value = "Agents";
			handleChangeBusinessUsers(value);
		}
	};
	/*

	* SubCategory i.e (Budget, Bedroom, Status, Listed By, Facing direction)

	*/
	const [SubCategoryChecked, setSubCategoryChecked] = React.useState<string>("");

	const handleClickSubCategoryChips = (data: string) => {
		setSubCategoryChecked(data);
	};

	const handleDeleteSubCategoryChips = (data: string) => {
		if (SubCategoryChecked === data) {
			setSubCategoryChecked("");
		}
	};

	React.useEffect(() => {
		if (
			search_filter_state.property_category.selected_options[0]?.id ||
			search_filter_state.property_type.selected_options[0]?.id
		) {
			setSubCategoryChecked("");
		}
	}, [search_filter_state.property_category, search_filter_state.property_type]);
	/*

	* Check the subcategory and render the content accordingly

	*/
	const SubCategoryChipsComponent = (
		<Stack
			sx={{
				display: "flex",
				flexDirection: "row",
				gap: "0.5rem",
				overflowX: "scroll",
				"&::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			{searchanimationsettings?.PropertySubCategory?.map((data: any, id: any) => (
				<PropertyChips
					key={id}
					sx={{
						fontSize: "1rem",
						backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: SubCategoryChecked === data
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",

						"&.MuiChip-clickable:hover": {
							backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						},
					}}
					label={data}
					clickable
					color="default"
					variant="outlined"
					deleteIcon={SubCategoryChecked === data ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					onDelete={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
					onClick={() => {
						SubCategoryChecked === data ? handleDeleteSubCategoryChips(data) : handleClickSubCategoryChips(data);
					}}
				/>
			))}
		</Stack>
	);

	const dataToRenderSubCategoryContent = (value: string) => {
		return (
			<React.Fragment>
				<CommonContainerSubCategory
					sx={{
						gap: "0.5rem",
						display: value === "Budget" ? "flex" : "none",
					}}
				>
					<BudgetComponent
						search_id="budget"
						dispatch={dispatch}
						search_filter_state={search_filter_state.budget}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: value === "Bedroom" ? "flex" : "none",
					}}
				>
					<BedroomComponent
						search_id="bedrooms"
						dispatch={dispatch}
						search_filter_state={search_filter_state.bedrooms}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: value === "Status" ? "flex" : "none",
					}}
				>
					<PropertyStatusComponent
						search_id="property_status"
						dispatch={dispatch}
						search_filter_state={search_filter_state.property_status}
						propertyType={search_filter_state.property_type.selected_options[0]?.id as string}
						transactionType={search_filter_state.transaction_type.selected_options[0]?.id as string}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: value === "Listed By" ? "flex" : "none",
					}}
				>
					<ListedByComponent
						search_id="listed_by"
						dispatch={dispatch}
						search_filter_state={search_filter_state.listed_by}
					/>
				</CommonContainerSubCategory>
				<CommonContainerSubCategory
					sx={{
						display: value === "Facing direction" ? "flex" : "none",
					}}
				>
					<FacingDirectionComponent
						search_id="land_facing"
						dispatch={dispatch}
						search_filter_state={search_filter_state.land_facing}
					/>
				</CommonContainerSubCategory>
			</React.Fragment>
		);
	};

	const SubCategoryContentComponent = <>{dataToRenderSubCategoryContent(SubCategoryChecked)}</>;

	/*

	* if tabs state changes then render the content accordingly

	*/

	const TransitionDivComponentToRender = (value: string) => {
		return (
			<>
				<div
					style={{
						display: value === "Buy" || value === "Rent" || value === "Open for business" ? "flex" : "none",
						flexDirection: "column",
						width: "100%",
						gap: "0.5rem",
					}}
				>
					<div
						style={{
							// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							padding: "1rem",
							borderRadius: "16px",
						}}
					>
						<TypographyHeading variant="subtitle2">Property category</TypographyHeading>
						<PropertyCategoryMobile
							search_id={"property_category"}
							search_filter_state={search_filter_state.property_category}
							dispatch={dispatch}
							TabValue={value}
							searchanimationsettings={searchanimationsettings}
						/>
						<TypographyHeading
							sx={{
								paddingTop: "1.5rem",
							}}
							variant="subtitle2"
						>
							Type of property
						</TypographyHeading>
						<PropertyTypeMobile
							search_id="property_type"
							property_category_value={search_filter_state.property_category?.selected_options[0]?.value as string}
							dispatch={dispatch}
							search_filter_state={search_filter_state.property_type}
							searchanimationsettings={searchanimationsettings}
							TabValue={value}
						/>
					</div>

					<div
						style={{
							// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							padding: "1rem",
							borderRadius: "16px",
						}}
					>
						<TypographyHeading
							variant="subtitle2"
							sx={{
								padding: "0rem 0rem 0.5rem 0rem",
							}}
						>
							Type of sub-category
						</TypographyHeading>
						{SubCategoryChipsComponent}
						{SubCategoryContentComponent}
					</div>
				</div>

				<div
					style={{
						display: value === "Services" ? "flex" : "none",
						flexDirection: "column",
						width: "100%",
						gap: "0.5rem",
					}}
				>
					<div
						style={{
							// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							padding: "1rem",
							borderRadius: "16px",
						}}
					>
						<TypographyHeading variant="subtitle2">Service Category</TypographyHeading>
						<ServiceCategoryMobile
							search_id={"service_type"}
							search_filter_state={search_filter_state.service_type}
							dispatch={dispatch}
							search_type={search_filter_state.search_type.selected_options[0].id as string}
						/>
					</div>

					<div
						style={{
							// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							padding: "1rem",
							borderRadius: "16px",
						}}
					>
						<TypographyHeading variant="subtitle2">Type of services</TypographyHeading>
						<ServiceSubTypeMobile
							search_id={"service_subtype"}
							dispatch={dispatch}
							search_filter_state={search_filter_state.service_subtype}
							search_type={search_filter_state.search_type.selected_options[0].id as string}
						/>
					</div>
				</div>

				<div
					style={{
						display: value === "Business Users" ? "flex" : "none",
						// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
						// boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
						backgroundColor: theme.palette.background.paper,
						backgroundImage: "none",
						padding: "1rem",
						borderRadius: "16px",
					}}
				>
					<FormControl>
						<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							sx={{ gap: "0.5rem", fontSize: "1rem", fontWeight: 400 }}
						>
							{search_filter_state.business_profile_type.active_options.map((data, id) => (
								<FormControlLabel
									key={id}
									value={data.value}
									control={<Radio />}
									label={data.title}
									onChange={() => handleChangeBusinessUsers(data.title)}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</div>
			</>
		);
	};
	const [isLoading, setIsLoading] = React.useState(false);
	const handleClickSearchButton = () => {
		if (isLoading) return;
		setIsLoading(true);

		if (!searchanimationsettings.StartAnimate) {
			if (historyState?.url !== "/search") {
				window.history.pushState(historyState, "", "/search");
			}
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: true,
					TriggerSearch: true,
					ResetSearch: searchanimationsettings.ResetSearch,
					PropertySubCategory: searchanimationsettings.PropertySubCategory,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
			setTrigger(false);
		} else {
			setTrigger(false);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: true,
					ResetSearch: true,
					PropertySubCategory: searchanimationsettings.PropertySubCategory,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
			dispatch(
				setSearchFilterMetricesThunk({
					boosted: [0],
					non_boosted: [0],
					filter_hash: "",
					no_of_boosted_results: 0,
					no_of_non_boosted_results: 0,
					total_no_of_results: 0,
				}),
			);
		}

		setIsLoading(false);
	};

	let location = "";
	if (
		typeof search_filter_state?.location.selected_options[0]?.value === "object" &&
		"title" in search_filter_state?.location.selected_options[0]?.value
	) {
		location = search_filter_state?.location.selected_options[0]?.value.title;
	}
	const [slide, setSlide] = React.useState<boolean>(search_filter_state.location.is_applied === true ? true : false);
	React.useEffect(() => {
		setSlide(search_filter_state.location.is_applied === true ? true : false);
	}, [search_filter_state.location.is_applied]);

	const locationSelect: any = ["HSR Layout", "Sarjapura Road", "Koramangala", "BTM Layout", "Jayanagar"];

	const handleClickLocationChips = (data: string) => {
		let lat: any = null;
		let lng: any = null;
		if (data === "HSR Layout") {
			lat = 12.9121;
			lng = 77.6446;
		} else if (data === "Sarjapura Road") {
			lat = 12.854922;
			lng = 77.788116;
		} else if (data === "Koramangala") {
			lat = 12.9352;
			lng = 77.6245;
		} else if (data === "BTM Layout") {
			lat = 12.9166;
			lng = 77.6101;
		} else if (data === "Jayanagar") {
			lat = 12.9308;
			lng = 77.5839;
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "location",
				newOptions: [
					{
						id: "location",
						title: "Location",
						value: {
							title: data,
							coordinates: [lng, lat],
						},
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "location",
				isApplied: true,
			}),
		);
	};

	const LocationChipsComponent = (
		<Stack
			sx={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				gap: "1rem",
				"&::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			{locationSelect?.map((data: any, id: any) => (
				<LocationChips
					key={id}
					sx={{
						height: "2.375rem",
						fontSize: "1rem",
						backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: SubCategoryChecked === data
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: SubCategoryChecked === data
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.23)",
						"&.MuiChip-clickable:hover": {
							backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
						},
					}}
					label={data}
					clickable
					color="default"
					variant="outlined"
					avatar={<AddIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />}
					// onDelete={() => {
					// 	SubCategoryChecked === data ? handleClickLocationChips(data) : handleClickLocationChips(data);
					// }}
					onClick={() => {
						SubCategoryChecked === data ? handleClickLocationChips(data) : handleClickLocationChips(data);
					}}
				/>
			))}
		</Stack>
	);
	const places = [
		"WhiteField",
		"Cubbon Park",
		"3rd Sector HSR Layout",
		"Koramangala",
		"Indiranagar",
		"Electronic City",
		"Marathahalli",
		"JP Nagar",
		"BTM Layout",
		"Jayanagar",
		"Bannerghatta Road",
		"Girinagar",
		"Malleshwaram",
	];

	const ClearAllFilters = () => {
		/*

		* let's get all the applied filters and store it as string array

		*/
		let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
			return (
				search_filter_state[key as SearchFilterId].is_applied === true &&
				key !== "transaction_type" &&
				key !== "search_type" &&
				key !== "location"
			);
		});
		6;

		/*

		* let's map the filters applied and update the search filter applied to false and selected options to empty array

		*/
		filtersapplied.map((filter) => {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: filter as SearchFilterId,
					newOptions: [],
				}),
			);
		});
	};

	return (
		<React.Fragment>
			<Container>
				<TextField
					sx={{
						display: "flex",
						justifyContent: "center",
						height: "100%",
						width: "100%",
						position: "relative",
						"& input": {
							position: "relative",
							zIndex: 1,
							color: "transparent",
							caretColor: "transparent",
						},
						"&:before": {
							content: search_filter_state.location.is_applied ? `"${location}"` : '"Search \\"WhiteField\\""',
							position: "absolute",
							top: "50%",
							left: "10px",
							transform: "translateY(-50%)",
							whiteSpace: "nowrap",
							overflow: "hidden",
							fontSize: "1rem",
							color: theme.palette.mode === "dark" ? "#ffffff" : "#666666",
							animation: search_filter_state.location.is_applied ? "none" : "placeholderAnimation 21s linear infinite",
						},
						"@keyframes placeholderAnimation": {
							/* very stupid format to implement */
							"0%, 8.33%": {
								content: `"Search \\"${places[0]}\\""`,
							},
							"8.34%, 16.66%": {
								content: `"Search \\"${places[1]}\\""`,
							},
							"16.67%, 25%": {
								content: `"Search \\"${places[2]}\\""`,
							},
							"25.01%, 33.33%": {
								content: `"Search \\"${places[3]}\\""`,
							},
							"33.34%, 41.66%": {
								content: `"Search \\"${places[4]}\\""`,
							},
							"41.67%, 50%": {
								content: `"Search \\"${places[5]}\\""`,
							},
							"50.01%, 58.33%": {
								content: `"Search \\"${places[6]}\\""`,
							},
							"58.34%, 66.66%": {
								content: `"Search \\"${places[7]}\\""`,
							},
							"66.67%, 75%": {
								content: `"Search \\"${places[8]}\\""`,
							},
							"75.01%, 83.33%": {
								content: `"Search \\"${places[9]}\\""`,
							},
							"83.34%, 91.66%": {
								content: `"Search \\"${places[10]}\\""`,
							},
							"91.67%, 100%": {
								content: `"Search \\"${places[11]}\\""`,
							},
						},
						"& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
							width: 0,
							minWidth: "0px",
						},
					}}
					variant="standard"
					onChange={(e) => {
						setValue(e.target.value);
					}}
					InputProps={{
						readOnly: true,
						disableUnderline: true,
						endAdornment: (
							<SearchIconContainer>
								<SearchIcon
									sx={{
										color: "#ffffff",
									}}
								/>
							</SearchIconContainer>
						),
					}}
					onClick={(e: any) => {
						setTrigger(true);
					}}
				/>

				<CustomSwipeableDrawer
					anchor="bottom"
					open={trigger}
					onClose={() => setTrigger(false)}
					onOpen={() => setTrigger(true)}
					swipeAreaWidth={156}
					disableSwipeToOpen={true}
					ModalProps={{
						keepMounted: true,
					}}
				>
					{/* <Snackbar
						sx={{
							bottom: "80px",
						}}
						open={!search_filter_state.location.is_applied ? true : false}
						message="Please enter a location"
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					/> */}
					<TypographyDiv
						sx={{
							backgroundColor: theme.palette.mode === "dark" ? "#4F3E2F" : "#FEE6D1",
							padding: "1rem 1rem 0rem 1rem",
						}}
					>
						<IconButton
							onClick={(e: any) => {
								DrawerClose();
								e.stopPropagation();
							}}
						>
							<ArrowBackIcon
								sx={{
									color: theme.palette.mode === "dark" ? "#A4A4A4" : "rgba(0, 0, 0, 0.5)",
								}}
							/>
						</IconButton>
						<DrawerContainer
							sx={{
								color: theme.palette.mode === "dark" ? "rgba(255, 255, 255,0.23)" : "rgba(102, 102, 102, 1)",
							}}
						>
							<StyledTabs
								sx={{
									width: "100%",
									marginBottom: "0.5rem",
								}}
								variant="scrollable"
								scrollButtons
								allowScrollButtonsMobile
								aria-label="scrollable force tabs example"
								value={value}
								onChange={handleChange}
							>
								<StyledTab
									value="Buy"
									label="Buy"
								/>
								<StyledTab
									value="Rent"
									label="Rent"
								/>
								<StyledTab
									value="Open for business"
									label="Open for business"
								/>
								<StyledTab
									value="Services"
									label="Services"
								/>
								<StyledTab
									value="Business Users"
									label="Business Users"
								/>
							</StyledTabs>
						</DrawerContainer>
					</TypographyDiv>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							height: "100%",
							overflowY: "scroll",
							gap: "1.5rem",
							padding: "1rem 1rem 0.25rem 1rem",
						}}
					>
						<DrawerContainer
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								alignContent: "center",
								gap: "5rem",
							}}
						>
							<LocationAutoCompleteMobile
								search_id="location"
								dispatch={dispatch}
								search_filter_state={search_filter_state.location}
							/>
						</DrawerContainer>

						{search_filter_state.location.is_applied ? (
							<Slide
								direction="up"
								in={slide}
								mountOnEnter
								unmountOnExit
							>
								<DrawerContainer
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										alignContent: "center",
										gap: "0.5rem",
									}}
								>
									{!searchanimationsettings.StartAnimate ? (
										TransitionDivComponentToRender(value)
									) : (
										<FilterMobileComponent
											search_filter_state={search_filter_state}
											dispatch={dispatch}
											searchanimationsettings={searchanimationsettings}
											search_filters_metrices={search_filters_metrices}
										/>
									)}
								</DrawerContainer>
							</Slide>
						) : (
							<DrawerContainer
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									justifyContent: "center",
									alignContent: "center",
									gap: "1rem",
								}}
							>
								<div>Popular areas in Bengaluru</div>
								{LocationChipsComponent}
							</DrawerContainer>
						)}
					</div>

					<ButtonCompoent
						sx={{
							// position: "fixed",
							height: "5rem",
							width: "100%",
							// bottom: 0,
							backgroundColor: theme.palette.mode === "dark" ? "#424242" : theme.palette.background.paper,
							borderRadius: "16px 16px 0px 0px",
							// boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.10)",
						}}
					>
						<BeegruButton
							flavor="primary"
							variant="text"
							size="medium"
							sx={{ fontSize: "1rem", alingnItems: "flex-start", height: "100%" }}
							onClick={() => {
								ClearAllFilters();
							}}
						>
							Clear all filters
						</BeegruButton>
						<BeegruButton
							flavor="primary"
							variant="contained"
							disabled={!search_filter_state.location.is_applied ? true : false}
							size="medium"
							sx={{
								fontSize: "1rem",
								padding: "0.35rem 0.rem 0.35rem 0.5rem",
								// height: "3rem",
								width: "auto",
							}}
							onClick={handleClickSearchButton}
						>
							Apply filters
						</BeegruButton>
					</ButtonCompoent>
				</CustomSwipeableDrawer>
			</Container>
			<IconContainer
				sx={{
					width: "fit-content",
					display: searchanimationsettings.StartAnimate ? "flex" : "none",
				}}
			>
				<IconButton
					sx={{
						padding: "0rem",
						[theme.breakpoints.between("md1220", "sm")]: {
							padding: "1rem",
						},
					}}
					onClick={(e: any) => {
						DrawerOpen();
						e.stopPropagation();
					}}
				>
					<FilterAltOutlinedIcon
						sx={{
							fontSize: "1.5rem",
							color: "#FC8019",
							[theme.breakpoints.up("sm")]: {
								fontSize: "2rem",
							},
						}}
					/>
				</IconButton>
			</IconContainer>
		</React.Fragment>
	);
};

export default Search;

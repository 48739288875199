import React from "react";
import { styled } from "@mui/system";

import { Skeleton, useTheme } from "@mui/material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "13rem",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "65%",
	overflow: "hidden",
}));

const TitleAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 0.5rem 0rem",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "0.5rem",
	width: "100%",
}));

const IconsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
	width: "30%",
}));

const ButtonDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
}));

const OFBListLoadingCard = () => {

	return (
		<React.Fragment>
			<MainContainer>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="33%"
					height="100%"
					style={{ borderRadius: "16px" }}
				/>

				<OFBCardInfoContainer>
					<TitleAndTransactionContainer>
						<Skeleton
							animation="wave"
							variant="text"
							width="60%"
							height="3rem"
							style={{ borderRadius: "12px" }}
						/>
						<IconsContainer>
							<Skeleton
								animation="wave"
								variant="text"
								width="5rem"
								height="3rem"
								style={{ borderRadius: "12px" }}
							/>
							<Skeleton
								animation="wave"
								variant="text"
								width="2rem"
								height="3rem"
								style={{ borderRadius: "12px" }}
							/>
						</IconsContainer>
					</TitleAndTransactionContainer>

					<CardRow>
						<Skeleton
							animation="wave"
							variant="text"
							width="48%"
							height="4rem"
							style={{ borderRadius: "12px" }}
						/>
						<Skeleton
							animation="wave"
							variant="text"
							width="48%"
							height="4rem"
							style={{ borderRadius: "12px" }}
						/>
					</CardRow>

					<ButtonDiv>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="48%"
							height="2rem"
							style={{ borderRadius: "12px" }}
						/>
					</ButtonDiv>
				</OFBCardInfoContainer>
			</MainContainer>
		</React.Fragment>
	);
};

export default OFBListLoadingCard;

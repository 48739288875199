/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";

/* Component Imports */

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Link, Skeleton, Typography } from "@mui/material";
import PremiumBadge from "../../../public/images/badges/premium.svg";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import Initials from "../Initials";
import CapitalizeFirstLetter from "../../../lib/CapitalizeFirstLetter";

/* Styled Components */

const BusinessProfileCardLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
}));

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	width: "100%",
	height: "13rem",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const Image = styled("img")(({ theme }) => ({
	height: "10.5rem",
	width: "10.5rem",
	borderRadius: "12px",
	objectFit: "cover",
}));

const ImageDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	height: "10.5rem",
	width: "10.5rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	gap: "1rem",
	width: "100%",
	height: "100%",
}));

const TitleAndTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.25rem",
	width: "100%",
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
}));

const BottomContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
}));

const ListingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "70%",
	background: theme.palette.background.default,
	borderRadius: "16px",
	padding: "0.75rem 1rem 0.75rem 1rem",
	gap: "1rem",
}));

const BusinessProfileCardListView = ({ _id }: { _id: ObjectId | string }) => {
	const [businessData, setBusinessData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (_id) {
			fetch(`${process.env.PRODUCTION_API_URL}business-profiles/cards/${_id}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setBusinessData(data.BusinessProfileData);
					setLoading(false);
				});
		}
	}, [_id]);

	if (loading) {
		/* Skeleton loading state */

		return (
			<React.Fragment>
				<MainContainer>
					<ImageDiv>
						<Skeleton
							variant="rectangular"
							width="10.5rem"
							height="10.5rem"
							animation="wave"
							style={{
								borderRadius: "12px",
							}}
						/>
					</ImageDiv>

					<InfoContainer>
						<TitleAndTypeContainer>
							<Skeleton
								variant="text"
								width="20%"
								height="2rem"
								animation="wave"
							/>

							<Skeleton
								variant="text"
								width="10%"
								height="1.75rem"
								animation="wave"
							/>
						</TitleAndTypeContainer>

						<BottomContainer>
							<ListingContainer sx={{ gap: "0.5rem" }}>
								<Skeleton
									variant="text"
									width="1.25rem"
									height="2rem"
									animation="wave"
								/>
								<Skeleton
									variant="text"
									width="8rem"
									height="1.5rem"
									animation="wave"
								/>
							</ListingContainer>

							<Skeleton
								variant="text"
								width="10.5rem"
								height="3.5rem"
								animation="wave"
								style={{
									borderRadius: "12px",
								}}
							/>
						</BottomContainer>
					</InfoContainer>
				</MainContainer>
			</React.Fragment>
		);
	} else {
		/* Card */

		return (
			<React.Fragment>
				<BusinessProfileCardLink
					href={`/${businessData.business_profile_type}s/${businessData.slug}`}
					rel="noopener"
					target={"_blank"}
					referrerPolicy="no-referrer"
					title={businessData.title ? businessData.title : "Business profile"}
				>
					<MainContainer>
						{/* Image */}

						<ImageDiv>
							{!businessData.display_picture_url?.includes("") ? (
								<Image
									alt="Display Picture"
									loading="lazy"
									referrerPolicy="no-referrer"
									height={512}
									width={512}
									src={businessData.display_picture_url}
								/>
							) : (
								<Initials
									title={businessData.title}
									profiledropdown={false}
									header={false}
									businessprofile={false}
									postcard={false}
									comment={false}
									search={true}
								/>
							)}
						</ImageDiv>

						<InfoContainer>
							<TitleAndTypeContainer>
								{/* Title */}

								<TitleContainer>
									<Typography variant="h6">
										{businessData.title ? CapitalizeFirstLetter(businessData.title) : ""}
									</Typography>
									{businessData.subscription_information?.active ? <PremiumBadge sx={{ height: "5rem" }} /> : null}
								</TitleContainer>

								{/* Type */}

								<Typography
									variant="body1"
									sx={{ width: "100%" }}
								>
									{businessData.business_profile_type ? CapitalizeFirstLetter(businessData.business_profile_type) : ""}
								</Typography>
							</TitleAndTypeContainer>

							{/* Listings Count */}

							<BottomContainer>
								<ListingContainer>
									<Typography
										variant="body1"
										fontWeight={500}
										sx={{ width: "100%" }}
									>
										{businessData.listingCount}
									</Typography>

									<Typography
										variant="body1"
										sx={{ width: "100%" }}
									>
										{businessData.business_profile_type === "professional"
											? businessData.listingCount === 1
												? "Service"
												: "Services"
											: businessData.listingCount === 1
												? "Property"
												: "Properties"}
									</Typography>
								</ListingContainer>

								{/* CTA Button */}

								<BeegruButton
									variant="outlined"
									color="primary"
									sx={{
										letterSpacing: "0.025rem",
										fontWeight: 400,
										width: "10.5rem",
									}}
									startIcon={<LocalPhoneOutlinedIcon />}
									href={
										businessData &&
										businessData.phone &&
										`tel:${businessData.phone.country_code} ${businessData.phone.phone_number}`
									}
								>
									Call now
								</BeegruButton>
							</BottomContainer>
						</InfoContainer>
					</MainContainer>
				</BusinessProfileCardLink>
			</React.Fragment>
		);
	}
};

export default BusinessProfileCardListView;

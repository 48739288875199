import { styled, useTheme } from "@mui/system";
import React from "react";
import {
	Amenities,
	Area,
	AvailableFrom,
	Balconies,
	Bathrooms,
	Bedrooms,
	Budget,
	BusinessProfileType,
	Floors,
	Furnishing,
	LandFacing,
	ListedBy,
	PlotDimensions,
	PossessionDate,
	PropertyCategory,
	PropertyStatus,
	PropertyType,
	SecurityDeposit,
	ServiceSubtype,
	ServiceType,
	UnitFacing,
} from ".";
import StateInterface from "../../redux-magic/state-interface";
import BeegruButton from "../common-components/buttons/BeegruButton";
import AppliedFilters from "./AppliedFilters";

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
}));

const DesktopFilterDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	gap: "0.5rem",
}));

const FilterMobileComponents = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
}) => {
	/*

	* only filters for mobile search page

	*/
	const theme = useTheme();

	let displayPropertyStatus = true;

	switch (search_filter_state.property_type.selected_options[0]?.id) {
		case "land_residential":
		case "land_commercial":
		case "land_agricultural":
		case "land_industrial":
		case "mining":
			displayPropertyStatus = false;
	}

	const [transaction_type, setTransactionType] = React.useState("");

	const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(false);

	React.useEffect(() => {
		if (search_filter_state.transaction_type.selected_options.length > 0) {
			setTransactionType(search_filter_state.transaction_type.selected_options[0].id === "ofb" ? "ofb" : "property");
		}
	}, [search_filter_state.transaction_type]);
	let BudgetTrigger = search_filter_state.business_profile_type.is_applied ? true : false;
	return (
		<>
			<CustomDiv
				sx={{
					"@keyframes CustomFadeOut": {
						"0%": {
							opacity: 0,
						},
						"25%": {
							opacity: 0,
						},
						"50%": {
							opacity: 0,
						},
						"75%": {
							opacity: 0.5,
						},
						"100%": {
							opacity: 1,
						},
					},
					animation: searchanimationsettings.StartAnimate ? "CustomFadeOut 0.5s linear 1" : "none",
					padding: "0.5rem 0rem 0.5rem 0rem",
				}}
			>
				<DesktopFilterDiv>
					<AppliedFilters
						search_filter_state={search_filter_state}
						dispatch={dispatch}
						searchanimationsettings={searchanimationsettings}
						search_type={search_filter_state.search_type.selected_options[0].id as string}
					/>

					{search_filter_state.service_type.is_visible && !search_filter_state.property_type.is_visible && (
						<ServiceType
							search_id="service_type"
							service_category_value={search_filter_state.service_category?.selected_options[0]?.value as string}
							dispatch={dispatch}
							service_type={search_filter_state.service_type}
						/>
					)}
					{search_filter_state.service_subtype.is_visible && !search_filter_state.property_type.is_visible && (
						<ServiceSubtype
							search_id="service_subtype"
							service_type_value={search_filter_state.service_type?.selected_options[0]?.id as string}
							dispatch={dispatch}
							service_subtype={search_filter_state.service_subtype}
						/>
					)}
					{search_filter_state.property_category.is_visible && !search_filter_state.service_type.is_visible && (
						<PropertyCategory
							search_id="property_category"
							property_category={search_filter_state.property_category}
							dispatch={dispatch}
						/>
					)}
					{search_filter_state.property_type.is_visible &&
						!search_filter_state.service_type.is_visible &&
						!["public_infrastructure", "joint_development", "joint_venture"].includes(
							search_filter_state.property_category?.selected_options[0]?.id,
						) && (
							<PropertyType
								search_id="property_type"
								property_category_value={search_filter_state.property_category?.selected_options[0]?.value as string}
								dispatch={dispatch}
								property_type={search_filter_state.property_type}
							/>
						)}
					{!BudgetTrigger && search_filter_state.budget.is_visible && (
						<Budget
							search_id="budget"
							dispatch={dispatch}
							budget={search_filter_state.budget}
						/>
					)}
					{search_filter_state.bedrooms.is_visible && search_filter_state.property_type.is_visible && (
						<Bedrooms
							search_id="bedrooms"
							dispatch={dispatch}
							bedrooms={search_filter_state.bedrooms}
						/>
					)}
					{showAdvancedFilters &&
						search_filter_state.area.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Area
								search_id="area"
								dispatch={dispatch}
								area={search_filter_state.area}
							/>
						)}
					{showAdvancedFilters &&
						search_filter_state.property_status.is_visible &&
						displayPropertyStatus &&
						search_filter_state.property_type.is_visible && (
							<PropertyStatus
								search_id="property_status"
								dispatch={dispatch}
								property_status={search_filter_state.property_status}
								property_type={search_filter_state.property_type}
								transaction_type={search_filter_state.transaction_type}
							/>
						)}
					{search_filter_state.unit_facing.is_visible && search_filter_state.property_type.is_visible && (
						<UnitFacing
							search_id="unit_facing"
							dispatch={dispatch}
							unit_facing={search_filter_state.unit_facing}
						/>
					)}
					{showAdvancedFilters &&
						search_filter_state.land_facing.is_visible &&
						search_filter_state.property_type.is_visible && (
							<LandFacing
								search_id="land_facing"
								dispatch={dispatch}
								land_facing={search_filter_state.land_facing}
							/>
						)}
					{showAdvancedFilters &&
						search_filter_state.furnishing.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Furnishing
								search_id="furnishing"
								dispatch={dispatch}
								furnishing={search_filter_state.furnishing}
							/>
						)}
					{showAdvancedFilters && search_filter_state.security_deposit.is_visible && (
						<SecurityDeposit
							search_id="security_deposit"
							dispatch={dispatch}
							security_deposit={search_filter_state.security_deposit}
						/>
					)}
					{showAdvancedFilters &&
						search_filter_state.amenities.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Amenities
								search_id="amenities"
								dispatch={dispatch}
								amenities={search_filter_state.amenities}
							/>
						)}

					{showAdvancedFilters &&
						search_filter_state.balconies.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Balconies
								search_id="balconies"
								dispatch={dispatch}
								balconies={search_filter_state.balconies}
							/>
						)}
					{showAdvancedFilters &&
						search_filter_state.bathrooms.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Bathrooms
								search_id="bathrooms"
								dispatch={dispatch}
								bathrooms={search_filter_state.bathrooms}
							/>
						)}
					{search_filter_state.plot_dimensions.is_visible && search_filter_state.property_type.is_visible && (
						<PlotDimensions
							search_id="plot_dimensions"
							dispatch={dispatch}
							plot_dimensions={search_filter_state.plot_dimensions}
						/>
					)}

					{search_filter_state.possession_date.is_visible && search_filter_state.property_type.is_visible && (
						<PossessionDate
							search_id="possession_date"
							dispatch={dispatch}
							possession_date={search_filter_state.possession_date}
						/>
					)}
					{search_filter_state.available_from.is_visible && search_filter_state.property_type.is_visible && (
						<AvailableFrom
							search_id="available_from"
							dispatch={dispatch}
							available_from={search_filter_state.available_from}
						/>
					)}

					{showAdvancedFilters &&
						search_filter_state.floors.is_visible &&
						search_filter_state.property_type.is_visible && (
							<Floors
								search_id="floors"
								dispatch={dispatch}
								floors={search_filter_state.floors}
							/>
						)}

					{search_filter_state.business_profile_type.is_visible &&
						search_filter_state.business_profile_type.is_applied && (
							<BusinessProfileType
								search_id="business_profile_type"
								dispatch={dispatch}
								business_profile_type={search_filter_state.business_profile_type}
							/>
						)}
					{!BudgetTrigger && showAdvancedFilters && search_filter_state.listed_by.is_visible && (
						<ListedBy
							search_id="listed_by"
							listed_by={search_filter_state.listed_by}
							dispatch={dispatch}
						/>
					)}
					{search_filter_state.business_profile_type.is_applied ? null : (
						<BeegruButton
							flavor="primary"
							variant="contained"
							size="large"
							sx={{
								dsiaplay: "flex",
								marginTop: "1rem",
								[theme.breakpoints.down("md")]: {
									width: "100%",
								},
							}}
							onClick={() => {
								setShowAdvancedFilters(!showAdvancedFilters);
							}}
							// endIcon={showAdvancedFilters ? <VisibilityIcon /> : <VisibilityOffIcon />}
						>
							{showAdvancedFilters ? "Hide" : "Show"} Advanced filters
						</BeegruButton>
					)}
					{/* <ServiceCategory
							search_id="service_category"
							dispatch={dispatch}
							service_category={search_filter_state.service_category}
						/> */}
				</DesktopFilterDiv>
			</CustomDiv>
		</>
	);
};
export default FilterMobileComponents;

import StateInterface from "../../../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOptionValueLocation,
	SearchFilterOptionValueRange,
	SearchableDatabaseCollections,
} from "../../../../redux-magic/sub-interfaces/search-types";
import { getFullTextSearchAnd2DSphereIndexName } from "./getFullTextSearchAnd2DSphereIndexNames";

/*

&  Let's define the types here

*/

type db_query_match = {
	$match: {};
};

export type db_query_geoNear = {
	$geoNear: {};
};

type db_query_both_stages = {
	$match: {};
	$geoNear: {};
};

type db_query_stages = db_query_match | db_query_geoNear | db_query_both_stages | null; // null is for search_type filter

/*

 & Define a record that will hold the database queries for each search filter.

*/

type BuildDatabaseQueriesOutput = Record<SearchFilterId, db_query_stages>;

export type output_type_of_database_query = {
	DBQueries: BuildDatabaseQueriesOutput;
	collection: SearchableDatabaseCollections;
};

/*

  & Now let's define the function that will build the database queries.

*/

const buildDatabaseQueries = (search_filters_state: StateInterface["search_filters_state"]) => {
	/*

		& Create an empty object that will hold the database queries for each search filter.


	*/

	const buildDatabaseQueriesOutput: BuildDatabaseQueriesOutput = {} as BuildDatabaseQueriesOutput;

	/*

		& Create a variable that will hold the collection name.

	*/

	let db_collection: SearchableDatabaseCollections = null;

	let land_area_option = false;

	/*

		& Let's sort the search filters state object based on is_applied true. This will help us to get the collection name. sorting will help us to get the collection name of the filter that is applied first.

	*/

	const appliedFilters = Object.values(search_filters_state)
		.filter((filter) => filter.is_applied)
		.sort((a, b) => a.priority - b.priority);

	/*

		& Get the collection name. If the collection name is null, we will check the search_type filter.

	*/

	db_collection = appliedFilters.find((filter) => filter.db_collection != null)?.db_collection || null;

	/*

		& If the collection name is still null, we will check the search_type filter.

	*/

	if (db_collection === null) {
		const search_type = appliedFilters.find((filter) => filter.id === "search_type");

		/*

			& If the search_type filter is found, we will set the collection name based on the selected option of the search_type filter.

		*/
		switch (search_type && search_type.selected_options[0].value) {
			case "properties":
				db_collection = "properties";
				break;
			case "services":
				db_collection = "services";
				break;
			case "business_profiles":
				db_collection = "business_profiles";
				break;
			case "users":
				db_collection = "users";
				break;
		}
	}

	/*

		& Get the full text search and 2D sphere index name.

	*/

	const fullTextSearchAnd2DSphereIndexName = getFullTextSearchAnd2DSphereIndexName(
		db_collection && db_collection ? db_collection : "properties",
	);

	/*

		& Loop through the search filters state. Here we will check if the filter is applied.

	*/

	for (const search_filter_id in search_filters_state) {
		/*

			& Get the filter id. Here is the filter id is the key of the search_filters_state object.

		*/

		const filterId = search_filter_id as SearchFilterId;

		if (search_filters_state[filterId].is_applied) {
			/*

				& Create an empty object that will hold the database query for the property_type search filter.

			*/

			let property_type_options: string[] = [];

			/*

				& Create a boolean variable that will help us to check if the property type filter has land area options.

			*/

			/*

				& Check if the filter id is property_type. If it is, get the selected options of the property_type filter. We will use this to check if the property type filter has land area options.

			*/

			if (filterId === "property_type") {
				property_type_options = search_filters_state[filterId].selected_options.map((option) => {
					return option.title;
				});

				land_area_option =
					property_type_options.includes("Land (Residential)") ||
					property_type_options.includes("Land (Commercial)") ||
					property_type_options.includes("Land (Agricultural)") ||
					property_type_options.includes("Land (Industrial)") ||
					property_type_options.includes("Mining");
			}

			const filter = search_filters_state[filterId];

			/*

				& 1. Check the option type of the filter. possible values are location, range, text. so we will check the option type and create the database query accordingly.

				& 2. If the option type is location, create a geoNear query. Check if the location has coordinates.

				& 3. If the option type is range, create a match query.

				& 4. If the option type is text, create a match query.

				& 5. Return the database queries.

			*/
			switch (filter.option_type) {
				case "location":
					/*

						& Here we are checking if the value of the active option has coordinates. If it has, we will create a geoNear query.

					*/

					if (filter.selected_options.length > 0 && filter.selected_options[0].value.hasOwnProperty("coordinates")) {
						// Here we are casting the value of the active option to SearchFilterOptionValueLocation

						const locationValue = filter.selected_options[0].value as SearchFilterOptionValueLocation;

						buildDatabaseQueriesOutput[filterId] = {
							$geoNear: {
								near: {
									type: "Point",
									coordinates: [locationValue.coordinates[0], locationValue.coordinates[1]],
									index: fullTextSearchAnd2DSphereIndexName,
									title: locationValue.title,
								},
								distanceField: "distance",
								spherical: true,
							},
						};
					}
					break;

				case "range":
					/*

					   & Here we are creating a function that will create a match query for the range filter. We will use this function to create a match query for the range filters.


				    */
					const createMatchQuery = (fieldName: string, rangeValue: SearchFilterOptionValueRange) => {
						let query: any = {};

						if (rangeValue.unlock_max) {
							query = {
								$match: {
									[`${fieldName}.min`]: {
										$gte: rangeValue.min,
									},
								},
							};
						}

						if (rangeValue.max === 0) {
							query = null;
						}

						query = {
							$match: {
								[`${fieldName}.min`]: {
									$gte: rangeValue.min,
									$lte: rangeValue.max,
								},
								[`${fieldName}.max`]: {
									$gte: rangeValue.min,
									$lte: rangeValue.max,
								},
							},
						};

						return query;
					};

					/*

						& Here we are checking if the value of the active option is an object and if it has a min and max property. If it has, we will create a match query.

					*/

					if (
						filter.selected_options.length > 0 &&
						(filter.selected_options[0].value as SearchFilterOptionValueRange) &&
						filter.db_field
					) {
						// Here we are casting the value of the active option to SearchFilterOptionValueRange

						const rangeValue = filter.selected_options[0].value as SearchFilterOptionValueRange;

						/*

							& Here we are checking the db_field of the filter. Based on the db_field, we will create a match query.

						*/

						switch (filter.db_field) {
							case "area":
								if (land_area_option) {
									if (rangeValue.unlock_max) {
										buildDatabaseQueriesOutput[filterId] = {
											$match: {
												["area.land_area.min.acre"]: {
													$gte: rangeValue.min,
												},
											},
										};
									}
									buildDatabaseQueriesOutput[filterId] = {
										$match: {
											["area.land_area.min.acre"]: {
												$gte: rangeValue.min,
												$lte: rangeValue.max,
											},
											["area.land_area.max.acre"]: {
												$gte: rangeValue.min,
												$lte: rangeValue.max,
											},
										},
									};
								} else {
									if (rangeValue.unlock_max) {
										buildDatabaseQueriesOutput[filterId] = {
											$match: {
												["area.super_built_up_area.min.ft"]: {
													$gte: rangeValue.min,
												},
											},
										};
									}
									if (rangeValue.max === 0) {
										buildDatabaseQueriesOutput[filterId] = null;
									}
									buildDatabaseQueriesOutput[filterId] = {
										$match: {
											["area.super_built_up_area.min.ft"]: {
												$gte: rangeValue.min,
												$lte: rangeValue.max,
											},
											["area.super_built_up_area.max.ft"]: {
												$gte: rangeValue.min,
												$lte: rangeValue.max,
											},
										},
									};
								}
								break;
							case "price":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("price", rangeValue);
								break;
							case "bedrooms":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("bhk", rangeValue);

								break;
							case "bathrooms":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("bathrooms", rangeValue);
								break;
							case "balconies":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("balconies", rangeValue);
								break;
							case "seats":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("no_of_seats", rangeValue);
								break;
							case "floors":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("floors", rangeValue);
								break;
							case "security_deposit":
								buildDatabaseQueriesOutput[filterId] = createMatchQuery("security_deposit", rangeValue);
								break;
							case "available_from":
								if (rangeValue.unlock_max) {
									buildDatabaseQueriesOutput[filterId] = {
										$match: {
											["possession_date"]: {
												$gte: rangeValue.min,
											},
										},
									};
								}

								buildDatabaseQueriesOutput[filterId] = {
									$match: {
										["possession_date"]: {
											$gte: rangeValue.min,
											$lte: rangeValue.max,
										},
									},
								};

								break;
							case "possession_date":
								if (rangeValue.unlock_max) {
									buildDatabaseQueriesOutput[filterId] = {
										$match: {
											["possession_date"]: {
												$gte: rangeValue.min,
											},
										},
									};
								}

								buildDatabaseQueriesOutput[filterId] = {
									$match: {
										["possession_date"]: {
											$gte: rangeValue.min,
											$lte: rangeValue.max,
										},
									},
								};
								break;
						}
					}
					break;
				case "text":
					/*

						& Here we are checking if the filter is multi or not. If it is multi, we will create a match query with $in operator. If it is not multi, we will create a match query with the value of the selected option.

					*/

					if (filter.multi) {
						if (filter.selected_options.length > 0 && filter.active_options.length !== filter.selected_options.length) {
							buildDatabaseQueriesOutput[filterId] = {
								$match: {
									[`${filter.db_field}`]: {
										$in: filter.selected_options.map((option) => option.title.toLowerCase()),
									},
								},
							};
						} else {
							buildDatabaseQueriesOutput[filterId] = null;
						}
					} else {
						if (filterId === "transaction_type" && filter.selected_options.length > 0) {
							if (filter.selected_options[0].value === "buy") {
								buildDatabaseQueriesOutput[filterId] = {
									$match: {
										[`${filter.db_field}`]: {
											$in: ["buy", "resale"],
										},
									},
								};
							} else {
								buildDatabaseQueriesOutput[filterId] = {
									$match: {
										[`${filter.db_field}`]: filter.selected_options[0].value,
									},
								};
							}
						} else if (db_collection === "business_profiles" && filterId !== "search_type") {
							buildDatabaseQueriesOutput[filterId] = {
								$match: {
									[`${filter.db_field}`]: filter.selected_options[0].value.toString().slice(0, -1),
								},
							};
						} else if (filterId !== "search_type" && filter.selected_options.length > 0) {
							buildDatabaseQueriesOutput[filterId] = {
								$match: {
									[`${filter.db_field}`]: filter.selected_options[0].value,
								},
							};
						} else {
							buildDatabaseQueriesOutput[filterId] = null;
						}
					}
					break;
			}
		}
	}

	/*

		& Return the database queries and the collection name.

	*/

	return {
		DBQueries: buildDatabaseQueriesOutput,
		collection: db_collection,
	} as output_type_of_database_query;
};

export default buildDatabaseQueries;

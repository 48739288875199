/* Common imports */
import React from "react";
/* mui imports */

import Brightness1Icon from "@mui/icons-material/Brightness1";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Slide,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";

/* library impoerts */
import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";

/* redux imports */
import StateInterface from "../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/thunks";

/* stylings */

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	borderRadius: "16px",
	boxShadow: "none",
	"&:: before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	padding: "var(--Spacing-spacing-sm, 8px) var(--Spacing-spacing-md, 16px)",
	margin: "0rem",
}));

const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
	width: "1.5rem",
	height: "1.5rem",
	borderRadius: "4px",
	color: theme.palette.mode == "dark" ? "#FFFFFF" : "#623816",
	backgroundColor: "#FC801929",
}));

const CustomCategoryIcon = styled(PaymentsOutlinedIcon)(({ theme }) => ({
	marginRight: "1rem",
	color: theme.palette.primary.main,
}));

const CustomSelectedIcon = styled(Brightness1Icon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	// flexWrap: "wrap",
	padding: "1rem",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.down("sm")]: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "1rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		width: "6.813rem",
		height: "2.125rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

export const _f_budget = ({
	search_id,
	dispatch,
	budget,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	budget: StateInterface["search_filters_state"]["budget"];
}) => {
	const theme = useTheme();

	const [minBudget, setMinBudget] = React.useState<number>(0);
	const [maxBudget, setMaxBudget] = React.useState<number>(0);
	const [slide, setSlide] = React.useState<boolean>(false);
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const minBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.min;

	const maxBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.max;

	const handleUpdateMinBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMinBudget(CurrencyToNumberPipe(e.target.value));
		if (maxBudget !== 0 && CurrencyToNumberPipe(e.target.value) > maxBudget) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: 0,
										max: maxBudget,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxBudget,
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: 0,
										max: maxBudget,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxBudget,
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	const handleUpdateMaxBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxBudget(CurrencyToNumberPipe(e.target.value));
		if (
			(minBudget !== 0 && CurrencyToNumberPipe(e.target.value) < minBudget) ||
			isNaN(CurrencyToNumberPipe(e.target.value))
		) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	React.useEffect(() => {
		const value = budget.selected_options[0]?.value;
		if (typeof value === "object" && "min" in value) {
			// Now TypeScript knows that value is of type SearchFilterOptionValueRange
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}
	}, [budget, search_id, dispatch]);

	return (
		<CustomAccordion
			square={true}
			disableGutters={true}
			onChange={() => {
				setSlide(!slide);
			}}
		>
			<CustomAccordionSummary
				expandIcon={<CustomArrowIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<CustomCategoryIcon /> Budget {(minBud || maxBud) > 0 ? <CustomSelectedIcon /> : null}
			</CustomAccordionSummary>
			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>
			<Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			>
				<CustomAccordionDetails>
					<Text
						value={minBud === 0 ? 0 : NumberToCurrencyPipe(minBud)}
						id="outlined-basic"
						placeholder="Min Budget"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinBudget(e);
						}}
						error={errorMinPrice}
						// helperText={errorMinPrice ? "Value is more than max budget" : NumberInWords(minBudget)}
						autoComplete="off"
					/>
					<SubText>to</SubText>
					<Text
						value={maxBud === 0 ? 0 : NumberToCurrencyPipe(maxBud)}
						id="outlined-basic"
						placeholder="Max Budget"
						variant="outlined"
						type="text"
						inputProps={{
							min: 0,
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxBudget(e);
						}}
						error={errorMaxPrice}
						// helperText={errorMinPrice ? "Value is less than min budget" : NumberInWords(maxBudget)}
						autoComplete="off"
					/>
				</CustomAccordionDetails>
			</Slide>
		</CustomAccordion>
	);
};

/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import CurrencyToNumberPipe from "../../../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../../../lib/NumberToCurrencyPipe";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/thunks";
/* redux imports */

/* Stylings */

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.813rem",
		fontWeight: "400",
		lineHeight: "1.125rem",
		// width: "100%",
		// height: "2.5rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	fontSize: "0.813rem",
	fontWeight: "400",
	lineHeight: "1.125rem",
	padding: "3px 6px",
}));

const BudgetComponent = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["budget"];
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const [minBudget, setMinBudget] = React.useState<number>(0);
	const [maxBudget, setMaxBudget] = React.useState<number>(0);
	const [slide, setSlide] = React.useState<boolean>(false);
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const handleUpdateMinBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMinBudget(CurrencyToNumberPipe(e.target.value));
		if (maxBudget !== 0 && CurrencyToNumberPipe(e.target.value) > maxBudget) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: 0,
										max: maxBudget,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxBudget,
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: 0,
										max: maxBudget,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: CurrencyToNumberPipe(e.target.value),
										max: maxBudget,
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	const handleUpdateMaxBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMaxBudget(CurrencyToNumberPipe(e.target.value));
		if (
			(minBudget !== 0 && CurrencyToNumberPipe(e.target.value) < minBudget) ||
			isNaN(CurrencyToNumberPipe(e.target.value))
		) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions:
					e.target.value === ""
						? [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: 0,
										unlock_max: true,
									},
								},
							]
						: [
								{
									id: "budget",
									title: "Budget",
									value: {
										min: minBudget,
										max: CurrencyToNumberPipe(e.target.value),
										unlock_max: true,
									},
								},
							],
			}),
		);
	};

	React.useEffect(() => {
		if (search_filter_state.selected_options.length === 0) {
			setMinBudget(0);
			setMaxBudget(0);
		}
		const value = search_filter_state.selected_options[0]?.value;

		if (typeof value === "object" && "min" in value) {
			const isApplied = value.min !== 0 || value.max !== 0;
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);

	return (
		<Stack
			direction="row"
			spacing={1}
		>
			<Text
				value={minBudget === 0 ? "" : NumberToCurrencyPipe(minBudget)}
				id="outlined-basic"
				placeholder="Min Budget"
				variant="outlined"
				type="text"
				inputProps={{
					min: 0,
					inputMode: "numeric",
					pattern: "[0-9]*",
				}}
				size="small"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					handleUpdateMinBudget(e);
				}}
				error={errorMinPrice}
				// helperText={errorMinPrice ? "Value is more than max budget" : NumberInWords(minBudget)}
				autoComplete="off"
			/>
			<SubText>to</SubText>
			<Text
				value={maxBudget === 0 ? "" : NumberToCurrencyPipe(maxBudget)}
				id="outlined-basic"
				placeholder="Max Budget"
				variant="outlined"
				type="text"
				size="small"
				inputProps={{
					min: 0,
					inputMode: "numeric",
					pattern: "[0-9]*",
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					handleUpdateMaxBudget(e);
				}}
				error={errorMaxPrice}
				// helperText={errorMinPrice ? "Value is less than min budget" : NumberInWords(maxBudget)}
				autoComplete="off"
			/>
		</Stack>
	);
};
export default BudgetComponent;

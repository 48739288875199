/* Common imports */

import React from "react";
/* mui imports */
import { Checkbox, FormControlLabel, FormGroup, Stack, useMediaQuery, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../../../redux-magic/thunks";
/* redux imports */

/* Stylings */
interface Item {
	id: string;
	value?: string;
	title?: string;
}
const BudgetComponent = ({
	search_id,
	search_filter_state,
	dispatch,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["land_facing"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const [FacingState, setState] = React.useState<Item[]>([]);

	const handleRemoveData = (item: string | undefined) => {
		setState((prevState) => prevState.filter((i) => i.value !== item));
	};

	const handleAddData = (data: Item) => {
		setState((prevState) => [...prevState, data]);
	};

	React.useEffect(() => {
		if (FacingState.length > 0) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: search_id,
					newOptions: FacingState as Array<SearchFilterOption>,
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: true,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [FacingState]);

	React.useEffect(() => {
		if (search_filter_state.selected_options.length === 0) {
			setState([]);
		}
	}, [search_filter_state]);
	return (
		<Stack
			direction="row"
			spacing={3}
			// marginBottom={2}
		>
			<FormGroup
				sx={{
					display: "flex",
					flexDirection: "row",
					[theme.breakpoints.down("xsPlus")]: { paddingLeft: "0.5rem" },
				}}
			>
				{search_filter_state.active_options.map((data: any) => (
					<FormControlLabel
						key={data.id}
						control={
							<Checkbox
								checked={FacingState.some((obj: Item) => obj.id === data.id)}
								onChange={(event) => {
									if (event.target.checked) {
										handleAddData(data);
									} else {
										handleRemoveData(data.value);
									}
								}}
							/>
						}
						label={data.title}
					/>
				))}
			</FormGroup>
		</Stack>
	);
};

export default BudgetComponent;
